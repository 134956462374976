<template>
  <button type="button" @click="$emit('onClick')" :disabled="disabled">
    {{ label || 'กด' }}
  </button>
</template>
<script>
export default {
  props: ['label', 'disabled'],
  emits: ['onClick'],
};
</script>
<style lang="scss" scoped>
button[type='button'] {
  appearance: none !important;
  background-image: linear-gradient(to right, #523ee8, #e821aa);
  color: #ffffff;
  border: none !important;
  border-radius: 8px;
  padding: 8px 32px;
  font-size: 1rem;
  font-weight: bold !important;
}

button[type='button']:disabled {
  cursor: not-allowed;
  background-color: lightgrey;
  background-image: none;
}
</style>
