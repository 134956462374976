// import dailyReport from "./dailyReport";
import tripReport from "./tripReport";
import overSpeedReport from "./overSpeedReport";
import positionReport from "./positionReport";
import fuelReport from "./fuelReport";
import fuelChartData from "./fuelChart";
import speedChartData from "./speedChart";
import ptoReport from "./ptoReport";
import idlingReport from "./idlingReport";
import geofenceReport from "./geofenceReport";
import summaryReport from "./summaryReport";
import temperatureReport from "./temperatureReport";
import fuelUsedRateSumReport from "./fuelUsedRateSumReport";
import tripA4Report from "./tripA4Report";
import enterExitGeofenceReport from "./enterExitGeofenceReport";

export default {
  // dailyReport,
  tripReport,
  overSpeedReport,
  positionReport,
  fuelChartData,
  speedChartData,
  fuelReport,
  ptoReport,
  idlingReport,
  geofenceReport,
  summaryReport,
  temperatureReport,
  fuelUsedRateSumReport,
  tripA4Report,
  enterExitGeofenceReport
};
