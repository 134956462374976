<template>
  <defaultLayout>
    <div class="route-modal">
      <div class="route-title">เส้นทาง</div>
      <div class="route-header">
        <div class="navigation-btn" @click="onChangeTab">
          <a-icon type="left" v-if="!displayRoutesList" />
          <div class="navigation-text">
            {{ displayRoutesList ? 'เพิ่มเส้นทาง' : 'เส้นทางทั้งหมด' }}
          </div>
        </div>
      </div>
      <div class="route-content">
        <div class="route-list" v-if="displayRoutesList">
          <div
            v-for="route in routes"
            :key="route.id"
            class="route-info-container"
          >
            <a-row type="flex">
              <a-col :span="20" @click="setSelectedRoute(route)">
                <div class="route-info">
                  <div class="type">
                    <icon-font
                      type="icon-Iconawesome-route"
                      style="padding-right: 5px"
                    />
                  </div>
                  <div class="name">{{ route.Name }}</div>
                </div>
              </a-col>
              <a-col :span="4">
                <a-popconfirm
                  title="ต้องการลบเส้นทาง?"
                  okText="ยืนยัน"
                  cancelText="ยกเลิก"
                  @confirm="onRemoveRoute(route.id)"
                  style="padding: 10px; font-size: 1.2rem"
                >
                  <div class="remove">
                    <icon-font type="icon-delete1" />
                  </div>
                </a-popconfirm>
              </a-col>
            </a-row>
          </div>
        </div>

        <div v-else>
          <div v-if="isReadOnly">
            <route-info
              :route="selectedRoute"
              :googleMap="this.$refs.googleMap"
              :directionsDisplay="directionsDisplay"
              :calculateInfoRouteList="calculateInfoRouteList"
              :calculateRouteDirection="calculateRouteDirection"
              @onClickEditIcon="onClickEditIcon"
            />
          </div>
          <div v-else>
            <route-form
              :route="selectedRoute"
              :googleMap="this.$refs.googleMap"
              :directionsDisplay="directionsDisplay"
              :calculateInfoRouteList="calculateInfoRouteList"
              :calculateRouteDirection="calculateRouteDirection"
              :onCreateWaypoints="onCreateWaypoints"
              :onRemoveWaypoints="onRemoveWaypoints"
              :latlngMap="`${clickedLat},${clickedLng}`"
              @onSave="onClickSave"
              @onClose="onClickClose"
              @clearWaypoints="onClearRouteWaypoints"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="google-map">
      <GmapMap
        :center="{ lat: 13.7460067, lng: 100.5440535 }"
        :zoom="this.mapZoom"
        map-type-id="roadmap"
        style="width: 100%; height: 100vh"
        ref="googleMap"
        :options="{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
          zoomControlOptions: {
            position: 3,
          },
          streetViewControlOptions: {
            position: 3,
          },
          clickableIcons: true,
        }"
        @click="clickPosition"
      >
      </GmapMap>
    </div>
  </defaultLayout>
</template>
<script>
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import routeInfo from '@/views/components/route/routeInfo.vue';
import routeForm from '@/views/components/route/routeForm.vue';
import { mapState, mapActions } from 'vuex';
import { IconFont } from '@/config/config';

/* {
  lat: 0,
  lng: 0,
  name: '',
  type: '',
} */

export default {
  name: 'trip',
  components: {
    defaultLayout,
    IconFont,
    routeInfo,
    routeForm,
  },
  data() {
    return {
      displayRoutesList: true,
      isReadOnly: false,
      selectedRoute: {
        id: '',
        name: '',
        positionList: [],
      },
      displayedPositionList: [{}],
      routeWaypoints: [],
      mapZoom: 12,
      directionsService: null,
      directionsDisplay: null,
      clickedLat: '',
      clickedLng: '',
    };
  },
  watch: {
    routeWaypoints(newWaypoints, oldWaypoints) {
      if (oldWaypoints.length > 0) {
        this.onRemoveWaypoints(oldWaypoints);
      }
      if (newWaypoints.length > 0) {
        this.onCreateWaypoints(newWaypoints, this.$refs.googleMap.$mapObject);
      }
    },
  },
  mounted() {
    if (this.routes <= 0) {
      this.getAllRoutes([this.selectedGroup.id]);
    }
  },
  computed: {
    ...mapState({
      selectedGroup: (state) => state.authen.selectedGroup,
      routes: (state) => state.route.routes,
    }),
  },

  methods: {
    ...mapActions({
      createRoute: 'route/createRoute',
      updateRoute: 'route/updateRoute',
      deleteRoute: 'route/deleteRoute',
      getAllRoutes: 'route/getAllRoutes',
    }),
    async calculateRouteDirection(positionList, isOptimizedActive) {
      if (this.directionsService === null) {
        this.directionsService = new window.google.maps.DirectionsService();
      }
      if (this.directionsDisplay === null) {
        this.directionsDisplay = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
        });
      }
      return await this.directionsService.route(
        {
          origin: positionList[0],
          destination: positionList[positionList.length - 1],
          travelMode: 'DRIVING',
          optimizeWaypoints: isOptimizedActive,
          waypoints: [...positionList]
            .splice(1, positionList.length - 2)
            .map((latLng) => {
              return { location: latLng, stopover: true };
            }),
        },
        (response, status) => {
          return status === 'OK' ? response : null;
        }
      );
    },
    calculateInfoRouteList(infoList, positionList) {
      const copyPositionList = [...positionList];
      const waypoints = [];

      let optimizedList = [copyPositionList.shift()];
      for (let i = 0; i < copyPositionList.length - 1; i++) {
        optimizedList.push(copyPositionList[infoList.waypoint_order[i]]);
      }

      optimizedList.push(copyPositionList[copyPositionList.length - 1]);
      positionList = [];
      for (let j = 0; j < optimizedList.length - 1; j++) {
        positionList.push(optimizedList[j]);
        positionList.push({
          name: `ระยะทาง ${infoList.legs[j].distance.text} เวลาเดินทาง ${infoList.legs[j].duration.text}`,
          type: 'info',
        });
      }
      positionList.push(optimizedList[optimizedList.length - 1]);

      infoList.legs.forEach((leg, index) => {
        if (index === 0) {
          waypoints.push({
            lat: leg.start_location.lat(),
            lng: leg.start_location.lng(),
          });
        }
        waypoints.push({
          lat: leg.end_location.lat(),
          lng: leg.end_location.lng(),
        });
      });

      this.routeWaypoints = [...waypoints].map((positionInfo, index) => {
        return {
          marker: new window.google.maps.Marker({
            position: { lat: positionInfo.lat, lng: positionInfo.lng },
            label: { text: (index + 1).toString(), color: 'white' },
          }),
          info: { label: positionList[index * 2].name },
        };
      });

      return positionList;
    },
    setDisplayRoutesList(value) {
      this.displayRoutesList = value;
    },
    async setSelectedRoute(route) {
      this.selectedRoute = {
        id: route.id,
        name: route.Name,
        positionList: route.Positions,
      };
      this.isReadOnly = true;
      this.setDisplayRoutesList(false);
    },
    onChangeTab() {
      this.setDisplayRoutesList(!this.displayRoutesList);
      this.isReadOnly = false;
      this.resetSelectedRoute();
      if (this.directionsDisplay) {
        this.directionsDisplay.setMap(null);
      }
    },
    async onClickSave(route) {
      if (route.id === '') {
        const createdResult = await this.createRoute({
          Name: route.name,
          positions: route.positions,
          org: this.selectedGroup.id,
        });
        if (createdResult) {
          this.getAllRoutes([this.selectedGroup.id]);
          this.setSelectedRoute(createdResult.data.createRouting.routing);
          this.$message.success('บันทึกเส้นทางสำเร็จ', 5);
        } else {
          this.$message.warning(
            'ไม่สามารถบันทึกเส้นทางได้ กรุณาลองอีกครั้ง',
            5
          );
        }
      } else {
        const updatedResult = await this.updateRoute({
          routeID: route.id,
          Name: route.name,
          positions: route.positions,
        });

        if (updatedResult) {
          this.getAllRoutes([this.selectedGroup.id]);
          this.setSelectedRoute(updatedResult.data.updateRouting.routing);
          this.$message.success('บันทึกเส้นทางสำเร็จ', 5);
        } else {
          this.$message.warning(
            'ไม่สามารถบันทึกเส้นทางได้ กรุณาลองอีกครั้ง',
            5
          );
        }
      }
    },
    async onRemoveRoute(id) {
      const removedResult = await this.deleteRoute(id);
      if (removedResult) {
        this.getAllRoutes([this.selectedGroup.id]);
        this.$message.success('ลบเส้นทางสำเร็จ', 5);
      } else {
        this.$message.warning('ไม่สามารถลบเส้นทางได้ กรุณาลองอีกครั้ง', 5);
      }
    },

    onClickClose() {
      if (this.selectedRoute.id === '') {
        this.displayRoutesList = true;
      } else {
        this.isReadOnly = true;
      }
    },
    onClickEditIcon() {
      this.isReadOnly = false;
      this.onClearRouteWaypoints();
    },
    onClearRouteWaypoints() {
      this.routeWaypoints = [];
    },
    onCreateWaypoints(waypointsList, map) {
      /* {marker: {
        lat: Number,
        lng: Number
      },
        info:{
          label: String
        }} */
      waypointsList.forEach((waypoint) => {
        const infowindow = new window.google.maps.InfoWindow({
          content: waypoint.info.label,
        });
        waypoint.marker.addListener('click', () => {
          infowindow.open({
            anchor: waypoint.marker,
            map: map,
          });
        });
        waypoint.marker.setMap(map);
      });
    },
    onRemoveWaypoints(waypointsList) {
      waypointsList.forEach((waypoint) => {
        waypoint.marker.setMap(null);
      });
    },
    resetSelectedRoute() {
      this.selectedRoute = {
        id: '',
        name: '',
        positionList: [],
      };
      this.displayedPositionList = [{}];
      this.onClearRouteWaypoints();
    },
    clickPosition(e) {
      this.clickedLat = parseFloat(e.latLng.lat().toFixed(7));
      this.clickedLng = parseFloat(e.latLng.lng().toFixed(7));
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;

.google-map {
  width: calc(100vw - 500px);
  display: inline-block;
  position: absolute;
  @media screen and (max-width: $md) {
    width: calc(100vw - 360px);
  }
}
.route-modal {
  padding: 40px 32px !important;
  width: 360px;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid #d3d4d6;
  padding: 15px 20px;
  height: 100%;
}

.route-title {
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #d6d5d5;
}

.route-header {
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: right;

  .navigation-btn {
    display: inline-block;
    cursor: pointer;
  }
  .navigation-text {
    display: inline-block;
    margin-left: 4px;
    border-bottom: 1px solid;
    line-height: 14px;
  }
}

.route-content {
  .route-info-container {
    &.active {
      background: #e3e3e3 0% 0% no-repeat padding-box;
      cursor: pointer;
    }
    &:hover {
      background: #efefef 0% 0% no-repeat padding-box;
      cursor: pointer;
    }

    background: none;
    margin: 5px 0 5px 0;
    border-radius: 7px;
    padding: 0px 0px 0px 0px;

    .route-info {
      text-align: left;
      padding: 10px 0px;
      font-size: 16px;
      color: #505050;
      line-height: 24px;

      .type {
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;
        margin: 0px 5px;
      }
      .name {
        display: inline-block;
        font-weight: normal;
        width: 200px;
        white-space: nowrap;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .route_list-icon-container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      .remove {
        display: block;
        float: right;
        &:hover {
          color: red;
        }
      }
    }
  }
  .route-list {
    max-height: calc(100vh - 155px);
    overflow: auto;
  }
}

.title-container {
  margin-bottom: 8px;
  display: flex;
  column-gap: 10px;
}

::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-track-piece:start {
  background: transparent !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}
</style>
