<template>
  <div
    :style="{
      padding: '0 !important',
      width: isMobileDevice() ? '400px' : '720px',
      display: 'inline-block',
      backgroundColor: '#FAFAFA',
      border: '1px solid #D3D4D6',
      padding: '15px 20px',
      height: '100vh',
      overflowY: 'scroll',
    }"
  >
    <h4>ค้นหาประวัติย้อนหลัง</h4>
    <vehicleSearch style="margin-right: 10px" />
    <a-range-picker
      @change="onDateChange"
      :style="{
        marginTop: '5px',
        width: isMobileDevice() ? '280px' : '360px',
        display: 'inline-block',
      }"
      :allowClear="true"
      :placeholder="['เริ้มต้น', 'สิ้นสุด']"
      :disabled="selectedVehicleTrip.id == null"
      v-model="dateRange"
      format="D MMM YYYY HH:mm"
      :showTime="{
        defaultValue: [
          moment().hour(0).minute(0).second(0).millisecond(0),
          moment().hour(23).minute(59).second(59).millisecond(999),
        ],
      }"
    />
    <a-button
      :md="16"
      type="primary"
      @click="search"
      :style="{
        width: isMobileDevice() ? '100px' : '280px',
        marginTop: '5px',
      }"
      :loading="searchLoading"
      :disabled="dateRange.length <= 0"
      >ค้นหา</a-button
    >
    <a-switch
      :md="4"
      :style="{
        marginLeft: '10px',
        marginRight: '10px',
      }"
      default-checked
      @change="onShowDataTable"
    />
    DataTable
    <a-switch
      :md="4"
      :style="{
        marginLeft: '10px',
        marginRight: '10px',
      }"
      default-checked
      @change="onShowMap"
    />
    Map
    <listResultPosition
      @setCenterPath="setCenterPath"
      v-if="positions.length > 0"
    />
  </div>
</template>
<script>
import listResultPosition from '@/views/components/history/listResultPosition';
import vehicleSearch from '@/views/components/history/vehicleSearch';
import { mapState, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import moment from '@/lib/time';
export default {
  components: { listResultPosition, vehicleSearch },
  props: {},
  data() {
    return {
      collapsed: true,
      dateRange: [
        moment().hour(0).minute(0).second(0).millisecond(0),
        moment().hour(23).minute(59).second(59).millisecond(999),
      ],
      searchLoading: false,
    };
  },
  methods: {
    moment,
    ...mapActions({
      searchTrip: 'trip/searchTrip',
      selectPosition: 'trip/selectPosition',
      searchPositions: 'position/searchPositions',
      setReportLoading: 'loading/setReportLoading',
      showTripSecondInfo: 'trip/showTripSecondInfo',
      setShowMapOnScreen: 'position/setShowMapOnScreen',
      setShowDataTableOnScreen: 'position/setShowDataTableOnScreen',
    }),
    onShowDataTable(value) {
      this.setShowDataTableOnScreen(value);
    },
    onShowMap(value) {
      this.setShowMapOnScreen(value);
    },
    isMobileDevice() {
      return isMobile;
    },
    onDateChange(value) {
      if (
        this.dateRange &&
        this.dateRange.length > 0 &&
        this.dateRange[0].unix() == this.dateRange[1].unix()
      ) {
        this.dateRange[0].subtract(1, 'd').unix();
      }
      this.dateRange = value;
    },
    async search() {
      this.searchLoading = true;
      //////// GA /////////
      this.$gtag.event('history_search_clicked');
      ////////////////////
      this.selectPosition(null);

      await this.searchPositions({
        imei: this.selectedVehicleTrip.device.imei,
        start: this.dateRange[0].unix(),
        stop: this.dateRange[1].unix(),
        customergroup: this.selectedGroup.id,
        overspeed: this.selectedVehicleTrip.overspeed_limit,
      });
      if (this.positions.length > 0) {
        // this.showTripSecondInfo(true);
        // this.setCenterPath();
        this.$emit('setCenterPath');
      } else {
        this.$message.warning('ไม่พบข้อมูลตำแหน่ง', 5);
        this.showTripSecondInfo(false);
      }

      this.searchLoading = false;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    setCenterPath() {
      this.$emit('setCenterPath');
    },
  },
  computed: mapState({
    vehicles: (state) => state.vehicle.vehicles,
    positions: (state) => state.position.positions,
    trips: (state) => state.vehicle.vehicles,
    selectedTrip: (state) => state.vehicle.selectedVehicle,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
    selectedGroup: (state) => state.authen.selectedGroup,
  }),
};
</script>
<style lang="scss"></style>
