<template>
  <a-layout-sider
    breakpoint="md"
    collapsedWidth="0"
    style="display: flex; justify-content: center"
  >
    <!-- Top menu -->
    <div class="header">
      <div class="heliot-logo">
        <img
          :src="require('../../assets/icons/heliot-logo.png')"
          width="90"
          style="margin-right: 5px"
        />
        <!-- <img
          :src="require('../../assets/icons/heliot-logo.png')"
          width="90"
          style="margin-right: 5px"
        /> -->
        <div class="release-note-btn" @click="onClickReleaseNoteModal">
          v {{ fleetVersion }}
        </div>
      </div>
      <div class="heliot-username" @click="() => toggleShowNotification()">
        <span
          :title="user.display_name"
          style="font-size: 14px; font-weight: 300; color: #ffff"
          class="truncated-text"
          >{{
            user && user.display_name && user.display_name.split(' ')[0]
          }}</span
        >
        <a-badge
          :count="unreadCount"
          :overflow-count="99"
          :offset="[0, 2]"
          :showZero="true"
          style="borderradius: 10px; margin: 10px 15px 10px 0; right: 0; top: 0"
        >
          <img :src="require('@/assets/icons/bell.svg')" class="badge-layout" />
        </a-badge>
      </div>

      <a-dropdown class="ant-dropdown-link" :trigger="['click']">
        <a style="color: #ffff" @click="(e) => e.preventDefault()">
          <span
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            "
          >
            กลุ่มลูกค้า
            {{ selectedGroup && selectedGroup.label }}
          </span>
          <a-icon style="padding: 3px 0px 0px 2px" type="down" />
        </a>
        <a-menu slot="overlay" @click="changeCustomerGroup">
          <a-menu-item v-for="group in customerGroups" :key="`${group.id}`">{{
            group.name
          }}</a-menu-item>
        </a-menu>
      </a-dropdown>
      <!-- </a-menu> -->
    </div>

    <!-- Middle menu -->

    <a-menu
      id="middle-left_sidebar-menu"
      theme="dark"
      mode="vertical"
      style="flex-grow: 1; margin-bottom: 10px"
      @click="handleClick"
    >
      <a-menu-item
        key="dashboard"
        :class="[selectedPage == 'dashboard' ? 'menu-active' : '']"
      >
        <img
          :src="require('../../assets/icons/dashboard.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">แดชบอร์ด</span>
      </a-menu-item>

      <a-menu-item
        key="track"
        :class="[selectedPage == 'track' ? 'menu-active' : '']"
      >
        <img
          :src="require('../../assets/icons/car_marker.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">ตำแหน่งรถ</span>
      </a-menu-item>
      <a-sub-menu style="opacity: 100">
        <div
          slot="title"
          style="font-weight: 300; text-align: left; margin-left: 3px"
        >
          <img
            width="18"
            height="18"
            :src="require('/src/assets/cctv_icon.svg')"
          />
          <span class="menu-text">กล้องวิดีโอ</span>
        </div>

        <a-menu-item
          key="video_monitoring"
          style="color: black !important; font-weight: 200"
        >
          กล้องวิดีโอ
        </a-menu-item>
        <a-menu-item
          key="video_playback"
          style="color: black !important; font-weight: 200"
        >
          วิดีโอย้อนหลัง
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item
        key="trip"
        :class="[selectedPage == 'trip' ? 'menu-active' : '']"
      >
        <img
          :src="require('../../assets/icons/navigation.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">ทริป</span>
      </a-menu-item>
      <a-menu-item
        key="history"
        :class="[selectedPage == 'history' ? 'menu-active' : '']"
      >
        <img
          :src="require('@/assets/icons/car_history.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">ประวัติรถ</span>
      </a-menu-item>
      <a-menu-item
        key="report"
        :class="[selectedPage == 'report' ? 'menu-active' : '']"
      >
        <img
          :src="require('@/assets/icons/report.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">รายงาน</span>
      </a-menu-item>
      <a-menu-item
        key="geofence"
        :class="[selectedPage == 'geofence' ? 'menu-active' : '']"
      >
        <img
          :src="require('@/assets/icons/geofence_flag.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">สถานี</span>
      </a-menu-item>
      <a-menu-item
        key="maintenance"
        :class="[selectedPage == 'maintenance' ? 'menu-active' : '']"
      >
        <img :src="require('@/assets/icons/bell.svg')" width="18" height="18" />
        <span class="menu-text">การแจ้งเตือน</span>
      </a-menu-item>
      <a-menu-item
        key="route"
        :class="[selectedPage == 'route' ? 'menu-active' : '']"
      >
        <img
          :src="require('@/assets/icons/route.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">เส้นทาง</span>
      </a-menu-item>
      <a-menu-item
        key="TMS"
        :class="[selectedPage == 'TMS' ? 'menu-active' : '']"
      >
        <img
          :src="require('@/assets/icons/truck.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">จัดการงาน</span>
      </a-menu-item>
      <!-- <a-menu-item
        key="drivers"
        :class="[selectedPage == 'drivers' ? 'menu-active' : '']"
      >
        <span>คนขับ</span>
      </a-menu-item> -->
    </a-menu>
    <!-- Bottom menu -->

    <div
      style="
        margin: 0px 16px 0px 16px;
        height: 2px;
        display: flex;
        align-items: center;
      "
    >
      <a-divider />
    </div>

    <div class="footer">
      <a-menu
        theme="dark"
        mode="inline"
        :inlineCollapsed="collapsed"
        @click="handleClick"
      >
        <a-menu-item
          key="setting"
          style="display: flex; align-items: center; margin: 0"
          :class="[selectedPage == 'setting' ? 'menu-active' : '']"
        >
          <img
            :src="require('@/assets/icons/setting.svg')"
            width="18"
            height="18"
          />
          <span class="menu-text">ตั้งค่า</span>
        </a-menu-item>
      </a-menu>
      <div class="menu-item" @click="() => onShowDoc()">
        <img
          :src="require('@/assets/icons/instruction_manual.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">คู่มือการใช้งาน</span>
      </div>
      <div class="menu-item" @click="() => onSignOut()">
        <img
          :src="require('@/assets/icons/logout.svg')"
          width="18"
          height="18"
        />
        <span class="menu-text">ออก</span>
      </div>
    </div>
    <a-modal
      id="release-note-modal"
      v-model="releaseNoteModal"
      :footer="null"
      centered
    >
      <div class="release-note-body">
        <h1 style="font-size: 28px; text-align: center; color: #5044df">
          มีอะไรใหม่?
        </h1>
        <div
          style="
            height: 16rem;
            background-color: #eaeaea;
            border-radius: 10px;
            padding: 1.5rem;
          "
        >
          <div
            style="position: relative; height: 100%; overflow-y: auto"
            v-html="desc"
          ></div>
        </div>
        <footer
          style="display: flex; justify-content: center; margin-top: 1.25rem"
        >
          <button
            v-if="releaseNoteLink && releaseNoteLink !== ''"
            class="read-all-btn"
            @click="onClickReadAll"
          >
            อ่านทั้งหมด
          </button>
        </footer>
      </div>
    </a-modal>
  </a-layout-sider>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { signOut } from '../../lib/authen';
import _ from 'lodash';
import { positionSocketConnect } from '@/vue-socket';
import { connectPositionSocket } from '@/vue-socket';
import AxiosService from '../../plugins/axios';
import endpoints from '../../config/config';

export default {
  data() {
    return {
      collapsed: true,
      selectedKeys: [],
      fleetVersion: '4.0.0',
      releaseNoteModal: false,
      desc: '',
      releaseNoteLink: '',
    };
  },
  computed: mapState({
    user: (state) => state.authen.user,
    plan: (state) => state.authen.plan,
    selectedGroup: (state) => state.authen.selectedGroup,
    customerGroups: (state) => state.authen.customerGroups,
    menuPermissions: (state) => state.authen.menuPermissions,
    geofenceData: (state) => state.geofence.geofences,
    selectedPage: (state) => state.authen.selectedPage,
    unreadCount: (state) => state.fcm.unreadCount,
    ...mapGetters({
      getUnread: 'fcm/getUnread',
    }),
  }),
  methods: {
    ...mapActions({
      updateVehicle: 'vehicle/updateVehicle',
      selectGroup: 'authen/selectGroup',
      setCustomerGroups: 'authen/setCustomerGroups',
      setCustomerPlan: 'authen/setCustomerPlan',
      selectVehicle: 'vehicle/selectVehicle',
      getAllGeofences: 'geofence/getAllGeofences',
      getAllDrivers: 'driver/getAllDrivers',
      resetGeofenceState: 'geofence/resetState',
      showVehicleBottomInfo: 'vehicle/showVehicleBottomInfo',
      setSelectedPage: 'authen/setSelectedPage',
      toggleShowNotification: 'authen/toggleShowNotification',
    }),
    async changeCustomerGroup(selectObj) {
      const customerGroup = _.find(this.customerGroups, {
        id: selectObj.key,
      });
      if (typeof positionSocketConnect != 'undefined')
        await positionSocketConnect.close();
      await this.selectGroup({
        id: selectObj.key,
        label: customerGroup.label,
        logo: customerGroup.Logo,
        ext: customerGroup.ext,
      });
      await this.setCustomerPlan(customerGroup.plan);
      await this.updateVehicle({
        groups: this.customerGroups,
        groupID: selectObj.key,
      });

      await connectPositionSocket();
      this.selectVehicle({});
      this.showVehicleBottomInfo(false);

      await this.getAllGeofences([this.selectedGroup.id]);
      await this.getAllDrivers([this.selectedGroup.id]);
    },
    onSignOut() {
      this.setSelectedPage('track');
      signOut({
        topic: '',
        message: '',
        type: '',
      });
      localStorage.removeItem('show_debt_modal');
    },
    onShowDoc() {
      window.open(
        'https://heliot.co.th/คู่มือการใช้งานfleet-by-heliot/',
        '_blank'
      );
    },
    handleClick(e) {
      if (this.$router.currentRoute.name == e.key) return;
      this.setSelectedPage(e.key);
      this.$router.push({ name: e.key }).catch(() => {});
      return;
    },
    async onClickReleaseNoteModal() {
      this.releaseNoteModal = true;
    },
    onClickReadAll() {
      window.open(this.releaseNoteLink, '_blank');
    },
  },
  async mounted() {
    await AxiosService.get(
      `${endpoints.strapiAPI}/release-notes?_sort=createdAt:DESC`
    )
      .then((res) => {
        const data = res.data;
        if (data.length > 0) {
          this.fleetVersion = data[0].version;
          this.desc = data[0].description;
          this.releaseNoteLink = data[0].link;

          const item = this.fleetVersion + '-' + data[0].updatedAt;
          if (localStorage.getItem('show_release_note') !== item) {
            this.releaseNoteModal = true;
            localStorage.setItem('show_release_note', item);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  },
};
</script>

<style lang="scss">
$md: 769px;
$xl: 1025px;

#release-note-modal {
  position: absolute;
  .ant-modal {
    width: 80vw !important;

    @media screen and (min-width: $xl) {
      width: 40vw !important;
    }

    .ant-modal-content {
      border-radius: 10px !important;
    }
  }
  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-track-piece:start {
    background: #d9d9d9 !important;
  }

  ::-webkit-scrollbar-track-piece:end {
    background: #d9d9d9 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
  }
}
.ant-layout-sider {
  flex: 0 0 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
  width: 140px !important;
  &.ant-layout-sider-collapsed {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }
}

.ant-layout-sider-children {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100% !important;
  width: 140px !important;
  left: 0 !important;
  margin-top: 0px !important;
}

.ant-dropdown-link {
  display: flex;
  justify-content: center;
  padding: 0px 8px;
  line-height: 1rem;
  font-size: 14px;
}

#middle-left_sidebar-menu {
  overflow-y: auto !important;
  scrollbar-color: transparent transparent;

  /* Hide the default scrollbar */
  scrollbar-width: none !important;
}

/* Make the scrollbar track transparent */
#middle-left_sidebar-menu::-webkit-scrollbar {
  width: 10px !important; /* Adjust as needed */
}

#middle-left_sidebar-menu::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* Style the scrollbar thumb if needed */
#middle-left_sidebar-menu::-webkit-scrollbar-thumb {
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ) !important; /* Adjust transparency as needed */
  border-radius: 5px !important;
}

.ant-menu-item {
  display: flex !important;
  text-align: left !important;
  padding-left: 18px !important;
  align-items: center !important;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: #fff !important;
  left: 140px !important;
}

.org-select {
  background-color: darkcyan;
  padding: 8px 14px;
  color: #fff;
  text-align: center;
  font-size: 1rem;
}

.release-note-body ol,
.release-note-body ul,
.release-note-body dl {
  padding-left: 1.25rem !important;
}
</style>
<style lang="scss" scoped>
$xxl: 1440px;
$md: 769px;

.header {
  height: 170px;
  @media screen and (max-width: $xxl) {
    height: 150px;
  }
}
.footer {
  height: 190px;
  @media screen and (max-width: $xxl) {
    height: 160px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu-item {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.heliot-logo {
  margin-top: 10px;
  margin-bottom: 5px;
}
.heliot-username {
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0;
}
.menu-active {
  background-color: #111 !important;
  color: #fff !important;
}

.menu-text {
  font-size: 14px;
  padding-left: 10px;
  align-items: center;
}

.badge-layout {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.truncated-text {
  max-width: 80%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to display */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.read-all-btn {
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.25rem 1.25rem;
  background-color: #523ee8;
  color: #fff !important;
  border: none;

  &:hover {
    background-color: #362ba2;
    color: #eaeaea !important;
  }
}

.release-note-body {
  font-size: 1rem;

  @media screen and (min-width: $md) {
    margin: 1.5rem 1.5rem 0.25rem 1.5rem;
  }
}

.release-note-btn {
  color: #e821aa;
  border-radius: 12px;
  border: 0;
  height: auto;
  padding: 0.25rem 0.75rem;
  font-size: 12px;
  line-height: 10px;
  margin-top: 10px;
  background-color: white;
  display: inline-block;
  cursor: pointer;
}
</style>
