<template>
  <div class="onboard-user-modal">
    <div class="onboard-user-container">
      <a-icon type="close" class="close" @click="onCloseOnboardUser()" />
      <h1>อัพเดทเลย !!!</h1>
      <div class="preview">
        <!-- <img
          :src="
            require('@/assets/icons/onboarding-dinocular/dinocular-dashboard.svg')
          "
        /> -->
        <img
          loading="lazy"
          :src="require('@/assets/gif/onboard_dinocular_2.0.gif')"
        />
      </div>
      <!-- <p>ติดตามง่ายๆ แค่ปลายนิ้ว <br />สะดวกใช้งานได้ทุกที่ ทุกเวลา</p> -->
      <div class="button-container">
        <div class="button" @click="onClickOnboardUser(platform.GOOGLE)">
          <img
            :src="require('@/assets/icons/onboarding-dinocular/playstore.svg')"
          />
          ดาวน์โหลดที่
          <div class="head">Google Play</div>
        </div>
        <div class="button" @click="onClickOnboardUser(platform.APPLE)">
          <img
            :src="require('@/assets/icons/onboarding-dinocular/apple.svg')"
          />
          ดาวน์โหลดที่
          <div class="head">App Store</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['onClose'],
  data() {
    return {
      platform: {
        APPLE: 'apple',
        GOOGLE: 'google',
      },
    };
  },
  methods: {
    onCloseOnboardUser() {
      this.$emit('onClose');
    },
    onClickOnboardUser(platform) {
      if (platform === this.platform.APPLE) {
        //////// GA /////////
        this.$gtag.event('dinocular-apple-download-clicked');
        ////////////////////
        window.open(
          'https://apps.apple.com/th/app/dinoculars-by-heliot/id1619733256',
          '_blank'
        );
      }

      if (platform === this.platform.GOOGLE) {
        //////// GA /////////
        this.$gtag.event('dinocular-google-download-clicked');
        ////////////////////
        window.open(
          'https://play.google.com/store/apps/details?id=th.co.heliot.dinoculars&pcampaignid=web_share',
          '_blank'
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$primary: #523ee8;

.onboard-user-modal {
  position: absolute;
  z-index: 990;
  background-color: white;
  width: 320px;
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  bottom: 0;
  right: 0;

  transition: bottom 1s, right 1s;

  .onboard-user-container {
    padding: 29px 19px 19px;
    position: relative;

    > h1 {
      font-size: 32px;
      color: $primary;
      font-weight: 200;
      line-height: 100%;
      margin: 0;
    }
    .preview {
      // width: 180px;
      // height: 160px;
      // margin: 0 auto;
      // overflow-y: hidden;
      margin-block: 10px;
      > img {
        width: 282px;
        height: 502px;
        object-fit: cover;
      }
    }

    .close {
      position: absolute;
      font-size: 20px;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    > p {
      font-size: 20px;
      margin: 0;
      padding: 20px 0;
      line-height: 24px;
    }
    .button-container {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .button {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        color: white !important;
        text-align: left;
        background-color: black;
        padding: 5px;
        cursor: pointer;

        > .head {
          display: inline-block;
          font-size: 20px;
          line-height: 10px;
        }
        > img {
          margin: 5px 10px 5px 0;
          float: left;
        }
      }
    }
  }
}

.onboard-user-modal:hover {
  bottom: 20px;
  right: 20px;
}
</style>
