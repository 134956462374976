import { apolloClient as strapiApollo } from '@/strapi-apollo';
import positionReport from '@/graphql/positionReport.gql';
import store from '@/store';
import moment from '@/lib/time';
import * as Sentry from '@sentry/vue';

export const getSpeedChartData = async (imeis, start, stop) => {
  await strapiApollo.resetStore();
  return await strapiApollo
    .query({
      // Query
      query: positionReport,
      variables: {
        imeis,
        start,
        stop,
      },
    })
    .then((eventData) => {
      const statusArray = {
        VEHICLE_MOVING: [],
        ENGINE_IDLE: [],
        ENGINE_STOP: [],
        OVER_SPEED: [],
      };
      let lastStatus = null;

      if (eventData.data.positions.length != 0) {
        eventData.data.positions.map((pos) => {
          let speed = pos.speed;
          if (pos.engineStatus) {
            if (speed) {
              statusArray[pos.engineStatus].push([
                moment.unix(pos.timestamp).valueOf(),
                speed,
              ]);
              if (isContinueStatus(lastStatus, pos.engineStatus))
                statusArray[pos.engineStatus].push([
                  moment.unix(pos.timestamp).valueOf(),
                  speed,
                ]);
              else {
                //reset and stop drawing last status
                statusArray[lastStatus].push([
                  moment.unix(pos.timestamp).valueOf() - 2,
                  speed,
                ]);
                statusArray[lastStatus].push([
                  moment.unix(pos.timestamp).valueOf() - 1,
                  null,
                ]);

                //start drawing new status
                statusArray[pos.engineStatus].push([
                  moment.unix(pos.timestamp).valueOf(),
                  speed,
                ]);
              }
            } else {
              statusArray[pos.engineStatus].push([
                moment.unix(pos.timestamp).valueOf(),
                null,
              ]);
            }
          }
          lastStatus = pos.engineStatus;
          return [moment.unix(pos.timestamp).valueOf(), speed ? speed : 0];
        });
        store.dispatch('loading/setReportLoading', false);
        const totalDistance = (
          (eventData.data.positions[eventData.data.positions.length - 1]
            .io[65] -
            eventData.data.positions[0].io[65]) /
          1000
        ).toFixed(2);
        return {
          chartData: [
            {
              name: 'รถวิ่ง',
              type: 'area',
              data: statusArray['VEHICLE_MOVING'],
            },
            {
              name: 'จอดติดเครื่อง',
              type: 'area',
              data: statusArray['ENGINE_IDLE'],
            },
            {
              name: 'รถจอด',
              type: 'area',
              data: statusArray['ENGINE_STOP'],
            },
          ],
          totalDistance,
        };
      }
    })
    .catch((err) => {
      console.log(err);
      store.dispatch('loading/setReportLoading', false);
      Sentry.captureException(err);
      return;
    });
};
const isContinueStatus = (lastStatus, currentStatus) => {
  if (lastStatus == null || lastStatus == currentStatus) return true;
  return false;
};

export default getSpeedChartData;
