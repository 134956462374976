<template>
  <apexchart
    type="area"
    height="350"
    :options="chartOptions"
    :series="speedChartData"
  ></apexchart>
</template>
<script>
import moment from '@/lib/time';
export default {
  props: {
    speedChartData: Array,
    overSpeedLimit: Number,
  },
  watch: {
    overSpeedLimit(val) {
      this.chartOptions = {
        ...this.chartOptions,
        annotations: {
          yaxis: [
            {
              y: val,
              borderColor: '#8e34ff',
              label: {
                borderColor: '#8e34ff',
                style: {
                  color: '#fff',
                  background: '#8e34ff',
                },
                text: `ความเร็วจำกัด ${val} กม./ชม.`,
              },
            },
          ],
        },
      };
    },
  },
  data: function () {
    return {
      chartOptions: {
        stroke: {
          curve: 'stepline',
          width: 1,
        },
        chart: {
          type: 'area',
          //   stacked: false,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
            },
          },
        },
        animations: {
          enabled: false,
        },

        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ['#00c246', '#FAAD14', '#F5223E'],
        },
        // theme: {
        //   palette: "palette8", // upto palette10
        // },

        colors: ['#00c246', '#FAAD14', '#F5223E'],
        fill: {
          colors: ['#00c246', '#FAAD14', '#F5223E'],

          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.0,
            opacityFrom: 1,
            opacityTo: 1,
            // inverseColors: false,
            // opacityFrom: 0.9,
            // opacityTo: 0.3,
          },
        },
        yaxis: {
          // min: -30,
          // max: 50,
          tickAmount: 6,
          labels: {
            formatter: function (val) {
              return `${val != null ? val.toFixed(0) : '-'} กม./ชม.`;
            },
            style: {
              colors: '#00c246',
            },
          },
          title: {
            text: 'ความเร็ว',
          },
        },
        xaxis: {
          tickAmount: 24,
          type: 'datetime',
          labels: {
            rotate: -45,
            rotateAlways: true,
            formatter: function (val, timestamp) {
              // console.log(val);
              return moment(new Date(timestamp)).format('HH:mm');
            },
          },
        },
        tooltip: {
          x: {
            format: 'HH:mm:ss',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: -10,
        },
      },
    };
  },
  computed: {
    chartData: function () {
      return [{}];
    },
  },
};
</script>
